const isProd = process.env.GATSBY_API_URL === 'https://kretz-prd-api.amexio-cloud.lu';
const isStaging = process.env.GATSBY_API_URL === 'https://kretz-staging-api.amexio-cloud.lu';

let siteUrl;

if (isProd) {
  siteUrl = 'https://kretzrealestate.com';
} else if (isStaging) {
  siteUrl = 'https://staging.kretzrealestate.com';
} else {
  siteUrl = 'http://localhost:8002';
}

const hreflangConfig = {
    '/fr/': {
        'fr': `${siteUrl}/fr/`,
        'en': `${siteUrl}/en/`,
        'es': `${siteUrl}/es/`,
        'pt': `${siteUrl}/pt/`
    },
    '/fr/buy/': {
        'fr': `${siteUrl}/fr/buy/`,
        'en': `${siteUrl}/en/buy/`,
        'es': `${siteUrl}/es/buy/`,
        'pt': `${siteUrl}/pt/buy/`
    },
    '/fr/sell/': {
        'fr': `${siteUrl}/fr/sell/`,
        'en': `${siteUrl}/en/sell/`,
        'es': `${siteUrl}/es/sell/`,
        'pt': `${siteUrl}/pt/sell/`
    },
    '/fr/biens-en-location/': {
        'fr': `${siteUrl}/fr/biens-en-location/`,
        'en': `${siteUrl}/en/biens-en-location/`,
        'es': `${siteUrl}/es/biens-en-location/`,
        'pt': `${siteUrl}/pt/biens-en-location/`
    },
    '/fr/agency/': {
        'fr': `${siteUrl}/fr/agency/`,
        'en': `${siteUrl}/en/agency/`,
        'es': `${siteUrl}/es/agency/`,
        'pt': `${siteUrl}/pt/agency/`
    },
    '/fr/off-market/': {
        'fr': `${siteUrl}/fr/off-market/`,
        'en': `${siteUrl}/en/off-market/`,
        'es': `${siteUrl}/es/off-market/`,
        'pt': `${siteUrl}/pt/off-market/`
    },
    '/fr/alert/': {
        'fr': `${siteUrl}/fr/alert/`,
        'en': `${siteUrl}/en/alert/`,
        'es': `${siteUrl}/es/alert/`,
        'pt': `${siteUrl}/pt/alert/`
    },
    '/fr/annonce': {
        'fr': `${siteUrl}/fr/annonce`,
        'en': `${siteUrl}/en/annonce`,
        'es': `${siteUrl}/es/annonce`,
        'pt': `${siteUrl}/pt/annonce`
    },
    '/fr/haussmannian-apartment/': {
        'fr': `${siteUrl}/fr/haussmannian-apartment/`,
        'en': `${siteUrl}/en/haussmannian-apartment/`,
        'es': `${siteUrl}/es/haussmannian-apartment/`,
        'pt': `${siteUrl}/pt/haussmannian-apartment/`
    },
    '/fr/dream-villa/': {
        'fr': `${siteUrl}/fr/dream-villa/`,
        'en': `${siteUrl}/en/dream-villa/`,
        'es': `${siteUrl}/es/dream-villa/`,
        'pt': `${siteUrl}/pt/dream-villa/`
    },
    '/fr/family-chalets/': {
        'fr': `${siteUrl}/fr/family-chalets/`,
        'en': `${siteUrl}/en/family-chalets/`,
        'es': `${siteUrl}/es/family-chalets/`,
        'pt': `${siteUrl}/pt/family-chalets/`
    },
    '/fr/typical-space/': {
        'fr': `${siteUrl}/fr/typical-space/`,
        'en': `${siteUrl}/en/typical-space/`,
        'es': `${siteUrl}/es/typical-space/`,
        'pt': `${siteUrl}/pt/typical-space/`
    },
    '/fr/garden-terrace/': {
        'fr': `${siteUrl}/fr/garden-terrace/`,
        'en': `${siteUrl}/en/garden-terrace/`,
        'es': `${siteUrl}/es/garden-terrace/`,
        'pt': `${siteUrl}/pt/garden-terrace/`
    },
    '/fr/private-hotel/': {
        'fr': `${siteUrl}/fr/private-hotel/`,
        'en': `${siteUrl}/en/private-hotel/`,
        'es': `${siteUrl}/es/private-hotel/`,
        'pt': `${siteUrl}/pt/private-hotel/`
    },
    '/fr/agency#tech': {
        'fr': `${siteUrl}/fr/agency/#tech`,
        'en': `${siteUrl}/en/agency/#tech`,
        'es': `${siteUrl}/es/agency/#tech`,
        'pt': `${siteUrl}/pt/agency/#tech`
    },
    '/fr/search': {
        'fr': `${siteUrl}/fr/search`,
        'en': `${siteUrl}/en/search`,
        'es': `${siteUrl}/es/search`,
        'pt': `${siteUrl}/pt/search`
    },
    '/fr/domain-and-castel': {
        'fr': `${siteUrl}/fr/domain-and-castel`,
        'en': `${siteUrl}/en/domain-and-castel`,
        'es': `${siteUrl}/es/domain-and-castel`,
        'pt': `${siteUrl}/pt/domain-and-castel`
    },
    '/fr/dream-villa': {
        'fr': `${siteUrl}/fr/dream-villa`,
        'en': `${siteUrl}/en/dream-villa`,
        'es': `${siteUrl}/es/dream-villa`,
        'pt': `${siteUrl}/pt/dream-villa`
    },
    '/fr/family-chalets': {
        'fr': `${siteUrl}/fr/family-chalets`,
        'en': `${siteUrl}/en/family-chalets`,
        'es': `${siteUrl}/es/family-chalets`,
        'pt': `${siteUrl}/pt/family-chalets`
    },
    '/fr/typical-space': {
        'fr': `${siteUrl}/fr/typical-space`,
        'en': `${siteUrl}/en/typical-space`,
        'es': `${siteUrl}/es/typical-space`,
        'pt': `${siteUrl}/pt/typical-space`
    },
    '/fr/garden-terrace': {
        'fr': `${siteUrl}/fr/garden-terrace`,
        'en': `${siteUrl}/en/garden-terrace`,
        'es': `${siteUrl}/es/garden-terrace`,
        'pt': `${siteUrl}/pt/garden-terrace`
    },
    '/fr/private-hotel': {
        'fr': `${siteUrl}/fr/private-hotel`,
        'en': `${siteUrl}/en/private-hotel`,
        'es': `${siteUrl}/es/private-hotel`,
        'pt': `${siteUrl}/pt/private-hotel`
    },
    '/fr/haussmannian-apartment': {
        'fr': `${siteUrl}/fr/haussmannian-apartment`,
        'en': `${siteUrl}/en/haussmannian-apartment`,
        'es': `${siteUrl}/es/haussmannian-apartment`,
        'pt': `${siteUrl}/pt/haussmannian-apartment`
    }
};

export default hreflangConfig;
